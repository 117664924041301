import {
  SET_LOADING_TRUE, SET_PROCESSING_STATE,
  SUBMIT_STRIPE_PAYMENT,
  SUBMIT_STRIPE_PAYMENT_COMPLETED,
  SUBMIT_STRIPE_PAYMENT_FAILED
} from "./actions";

const initialState = {
  submitting: false,
  processing: false,
};

export default function stripePaymentReducer(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_STRIPE_PAYMENT:
      return {
        ...state,
        submitting: true,
      };
    case SUBMIT_STRIPE_PAYMENT_COMPLETED:
      return {
        ...state,
        submitting: false,
      };
    case SUBMIT_STRIPE_PAYMENT_FAILED:
      return {
        ...state,
        submitting: false,
      };
    case SET_PROCESSING_STATE:
      return {
        ...state,
        processing: action.payload,
      };
    default:
      return state;
  }
};