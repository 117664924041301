import LoadingComponent from 'componentsTemplate/Loading';
import loadable from 'react-loadable';

export const DASHBOARD_PAGE_ROUTE = "/dashboard"
let Dashboard = loadable({
  loader: () => import('pagesPublic/Dashboard/'),
  loading: LoadingComponent
})

export const JOB_RECOMMENDER_PAGE_ROUTE = "/job-recommender"
let JobRecommender = loadable({
  loader: () => import('pagesPublic/JobRecommender/'),
  loading: LoadingComponent
})

export const LABOR_MARKET_INFO_PAGE_ROUTE = "/labor-market-info"
let LaborMarketInfo = loadable({
  loader: () => import('pagesPublic/LaborMarketInfo/'),
  loading: LoadingComponent
})

const publicRoutes = [
  { path: DASHBOARD_PAGE_ROUTE, exact: true, name: 'Dashboard', component: Dashboard},
  { path: JOB_RECOMMENDER_PAGE_ROUTE, exact: true, name: 'Job Recommender', component: JobRecommender},
  { path: LABOR_MARKET_INFO_PAGE_ROUTE, exact: true, name: 'Labor Market Info', component: LaborMarketInfo},
];

export default publicRoutes;
