import React from 'react';
import { render } from 'react-dom';
import Root from 'Root';

import * as serviceWorker from './serviceWorker';
import { history } from './store/configureStore';
import store from './store';
export {store}


render(
  <Root store={store} history={history} />,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
