export const SUBMIT_STRIPE_PAYMENT = "SUBMIT_STRIPE_PAYMENT";
export const SUBMIT_STRIPE_PAYMENT_COMPLETED = "SUBMIT_STRIPE_PAYMENT_COMPLETED";
export const SUBMIT_STRIPE_PAYMENT_FAILED = "SUBMIT_STRIPE_PAYMENT_FAILED";

export const submitStripePayment = (payment_info, onSuccess, onError) => ({
  type: SUBMIT_STRIPE_PAYMENT,
  payload: payment_info,
  meta: {
    async: true,
    path: "payment/",
    method: "POST",
    data: payment_info,
    success: onSuccess,
    failure: onError,
  },
});

export const SET_PROCESSING_STATE = "SET_PROCESSING_STATE";

export const setProcessingState = (isProcessing) => ({
  type: SET_PROCESSING_STATE,
  payload: isProcessing
});