import {SET_TOKEN} from "state/ducks/token/actions";


const initialState = null;

const tokenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      return action.payload
    default:
      return state;
  }
};

export default tokenReducer
