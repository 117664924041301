import AppRouter from "@bit/samsteady.react-generic.routing.app-router";
import LoadingComponent from 'componentsTemplate/Loading';
import queryString from "query-string"
import React, {useEffect, useMemo} from 'react';
import loadable from 'react-loadable';
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import {connect} from "react-redux";
import publicRoutes from "routes/publicRoutes";
import {setToken} from "state/ducks/token/actions";
// 3rd
import 'styles/antd.less';
import 'styles/bootstrap/bootstrap.scss'
// custom
import "styles/layout.scss"
import "styles/theme.scss"
import "styles/ui.scss"
import "styles/vendors.scss"


let PublicPagesLayout = loadable({
  loader: () => import('componentsTemplate/Layout/PublicPagesLayout/'),
  loading: LoadingComponent
})


function App({location : {search}, setToken, ...rest}) {



  let {auth_token} = useMemo(() =>
    queryString.parse(search)
    , [search]);

  useEffect(() =>
    auth_token && setToken(auth_token)
    ,[auth_token])


  return (
    <div id="app">
      <PublicPagesLayout {...rest}>
        <AppRouter
          routes={publicRoutes}
          defaultRoute={"/dashboard"}
        />
      </PublicPagesLayout>
    </div>
  );
}

function mapStateToProps(state, props) {
  return {};
}

function mapDispatchToProps(dispatch, props) {
  return {
    setToken: (token) => {dispatch(setToken(token))},
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

// export default App
